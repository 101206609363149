.projects {
    background: #05668D;
    padding-bottom: 15px;;
}

.projects-container {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    /* align-items: center; */

    width: 90%;

    margin: 0 auto;
}

.project-card {
    display: flex;
    /* flex-direction: column; */
    /* justify-content: center; */
    align-items: center;

    width: 60%;
    
    padding: 0 10px 0px 0;
    margin: 10px;
    border: 2px solid black;
    border-radius: 15px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    
    background:#feffed;
    color: #05668D;
    
    text-align: center;
    
}

.card-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    height: 100%;

    padding: 10px 5px;
}

.project-card img {
    width: 40%;
    height: 100%;

    /* justify-self: flex-start; */

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.project-card h1 {
    margin: 0;
}

.project-card p {
    font-size: 12px;
    padding: 5px;
}

.project-card-links {
    width: 100%;
    display: flex;
    justify-content: space-around;

    margin-bottom: 10px;

    /* background: red; */
    justify-self: flex-end;
}

.project-card a {
    text-decoration: none;
    font-weight: 600;
    color:#00A896;

    cursor: pointer;
}

.project-card a:hover {
    font-weight: 800;
}

@media only screen and (max-width: 600px) {
    .project-card {
        width:50%;
        padding: 0px;

        flex-direction: column;
    }
    
    .project-card h1 {
        font-size: 20px;
    }

    .project-card p {
        font-size: 13px;
    }
    
    .project-card img {
        width: 100%;
        border-radius: 0px;

        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    
    .project-card-links {
        width: 90%;
    }
}