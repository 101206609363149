.skills {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #F0F3BD;
    color: #05668D;
}

.skills-card-container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    padding: 0 0 30px 0;
}

.skill-card {
    width: 25%
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 25px;
        text-align: center;
    }

    h2 {
        font-size: 20px;
        text-align: center;
    }

    p {
        font-size: 15px;
        text-align: center;
    }
}