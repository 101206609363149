.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* padding: 5px 0; */
    margin: 0 auto;
    /* border-radius: 10%; */

    width: 100%;
    height: calc(100vh - 75px);

    background: #05668D;
    color: #F0F3BD;
}

.headshot {
    width: 100%;
    height: 50%;
    /* background: red; */
    text-align: center;
}

.hero-container img {
    width: auto;
    height: 100%;

    /* margin: 10px 0 10px 0; */

    background-color: aqua;
    border-radius: 50%;
}

.greeting {
    font-size: 50px;
    padding: 0;
    margin: 0;
    color: #dfbe44;
}

.hero-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-info svg {
    font-size: 45px;
    margin: 5px 10px;
    color: #dfbe44;
}

.lead-statement {
    color: #dfbe44;
}

.statement-container {
    display: flex;
    justify-content: space-around;
    
    /* margin: 2%; */

    width: 90%;
}

.statements {
    width: 20%;
    
    /* padding: 15px; */

    text-align: center;
}

@media only screen and (max-width: 600px) {
    .headshot {
        height: 25%
    }
    
    .greeting {
        font-size: 20px;
    }

    .lead-statement {
        text-align: center  ;

        font-size: 12px;
    }

    .statement-container {
        flex-direction: column;
        justify-content: flex-end;

    }

    .statements {
        width: 100%;
        font-size: 14px;
    }
}